<!--
   [2024/08/16 sb.hwang]
   StarXrDocsLayout.vue
   @note STAR-XR docs 레이아웃
-->
<template>
    <v-container fluid class="star-xr-docs-layout">
        <v-layout row justify-center>
            <v-flex class="star-xr-docs-layout-wrap d-flex">
                <star-xr-docs-nav
                    v-if="$vuetify.breakpoint.mdAndUp"
                    :routerNm="routerNm"
                    id="docs-nav"
                    class="star-xr-docs-nav"
                />
                <v-container fluid class="star-xr-docs-layout-container">
                    <router-view />
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import StarXrDocsNav from "./StarXrDocsNav.vue";

export default {
    name: "StarXrDocsLayout",
    components: {
        StarXrDocsNav,
    },
    computed: {},
    data() {
        return {
            routerNm: "",
        };
    },
    methods: {
        /**
         * @method changeMenus
         * @param {String} path 현재 페이지 path
         * @note 페이지에 맞는 메뉴로 반환하는 함수
         * @email gy.yang@naviworks.com
         */
        changeMenus(path) {
            if (path == "api") {
                this.routerNm = "api";
            } else {
                this.routerNm = "document";
            }
        },
        /**
         * @method scrollEvents
         * @note 스크롤에 따른 nav height 조절 함수
         * @email gy.yang@naviworks.com
         */
        scrollEvents() {
            let footerTop = document
                .getElementsByClassName("footer")[0]
                .getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let nav = document.getElementsByClassName("star-xr-docs-nav")[0];

            if (footerTop <= windowHeight) {
                nav.style.bottom = windowHeight - footerTop + "px";
            } else {
                nav.style.bottom = "0px";
            }
        },
    },
    created() {
        const routerPath = this.$route.path.split("/")[1];

        this.changeMenus(routerPath);
    },
    watch: {
        "$route.path"(value) {
            const routerPath = value.split("/")[1];

            this.changeMenus(routerPath);
        },
    },
    mounted() {
        document.addEventListener("scroll", this.scrollEvents, true);
    },
    beforeDestroy() {
        document.removeEventListener("scroll", this.scrollEvents, true);
    },
};
</script>

<style scoped>
.star-xr-docs-layout {
    min-height: calc(100vh - 140px);
}

.star-xr-docs-layout-wrap {
    background: #ffffff;
    min-height: calc(100vh - 140px);
}

.star-xr-docs-layout-container {
    position: relative;
    margin-left: 300px;
    padding: 150px 120px;
}

.star-xr-docs-nav {
    height: auto !important;
    padding-top: 70px;
    position: fixed;
    bottom: 0px;
}

/* vuetify lg(<1904px) */
@media (max-width: 1904px) {
    .star-xr-docs-layout-container {
        padding: 100px;
    }
}

/* vuetify md(<1264px) */
@media (max-width: 1264px) {
    .star-xr-docs-layout-container {
        padding: 40px;
    }
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .star-xr-docs-layout-container {
        margin-left: 0;
    }
}
</style>
