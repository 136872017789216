export const signUpStep = {
    Terms: {
        component: "StarXrSignUpTerms",
        title: "signUp",
    },
    InfoEmail: {
        component: "StarXrSignUpInfoEmail",
        title: "signUp",
    },
    CheckEmail: {
        component: "StarXrSignUpCheckEmail",
        title: "emailVerification ",
    },
    InfoPw: {
        component: "StarXrSignUpInfoPw",
        title: "setPassword",
    },
    InfoCompany: {
        component: "StarXrSignUpInfoCompany",
        title: "enterUserInfo",
    },
    InfoName: {
        component: "StarXrSignUpInfoName",
        title: "enterUserInfo",
    },
    End: {
        component: "StarXrSignUpEnd",
        title: "signUpComplete",
    },
};

export const findPwStep = {
    InfoEmail: "StarXrFindPwInfoEmail",
    CheckEmail: "StarXrFindPwCheckEmail",
    ChagnePw: "StarXrFindPwChangePw",
    End: "StarXrFindPwEnd",
};

// 임시
export const accessStatus = {
    0: "pendingApproval",
    1: "approvalStatus",
    2: "approvalRejected",
};

export const serviceStatus = {
    0: "업로드 대기",
    1: "업로드 완료",
};

export const approvedStatus = {
    0: "pendingApproval",
    1: "approved",
    2: "approvalRejected",
};

export const authrtStatus = {
    0: "user",
    1: "systemAdministrator",
    2: "superAdministrator",
};

export const srvcCategory = {
    1: "healthCare",
    2: "social",
    3: "E-commerce",
    4: "others",
};

export const langCd = {
    ko: 1,
    en: 2,
};

export const unityLangCd = {
    ko: "ko-KR",
    en: "en-US",
};
