<!--
   [2024/08/22 sb.hwang]
   StarXrServiceListItems.vue
   @note STAR-XR service list 카테고리별 아이템 묶음 컴포넌트
-->
<template>
    <div class="star-xr-service-list-items-wrap">
        <div class="star-xr-service-category">
            <div class="star-xr-service-category-title font-semi-bold">
                {{ $t(srvcCategory[items.srvcCtgrySn]) }}
            </div>
            <v-icon color="#202020" small> mdi-chevron-right </v-icon>
        </div>
        <div class="service-list-item">
            <star-xr-service-list-item
                v-for="(service, i) in filterServices"
                :key="i"
                :service="service"
            />
            <div v-if="!filterServices.length" class="star-xr-h5 font-gray7">
                {{
                    $t("noMeetingService", {
                        service: srvcCategory[items.srvcCtgrySn],
                    })
                }}
            </div>
        </div>
    </div>
</template>

<script>
import StarXrServiceListItem from "@/components/service/StarXrServiceListItem.vue";
import { srvcCategory } from "@/commons/resources";

export default {
    name: "StarXrServiceListItems",
    props: {
        items: {
            type: Object,
        },
        search: {
            type: String,
        },
    },
    components: {
        StarXrServiceListItem,
    },
    computed: {
        filterServices() {
            return this.items.services.filter(
                (item) =>
                    item.srvcTtl
                        .toLowerCase()
                        .includes(this.search.toLowerCase()) ||
                    item.srvcTtl
                        .toUpperCase()
                        .includes(this.search.toUpperCase())
                    // item.srvcCn
                    //     .toLowerCase()
                    //     .includes(this.search.toLowerCase()) ||
                    // item.srvcCn
                    //     .toUpperCase()
                    //     .includes(this.search.toUpperCase())
            );
        },
    },
    data() {
        return {
            srvcCategory,
        };
    },
    methods: {},
};
</script>

<style scoped>
.star-xr-service-list-items-wrap {
    margin-bottom: 72px;
}
.star-xr-service-category {
    display: flex;
    align-items: center;
    margin-top: 26px;
    margin-bottom: 12px;
    gap: 18px;
}
.star-xr-service-category-title {
    font-size: 20px;
}
.service-list-item {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px;
}
</style>
