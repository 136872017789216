<!--
   [2024/08/22 sb.hwang]
   StarXrServiceDetailRec.vue
   @note STAR-XR service 상세 화면 추천 목록 컴포넌트
-->
<template>
    <div class="service-detail-rec-wrap">
        <star-xr-service-list-item
            v-for="(service, i) in visibleServices"
            :key="i"
            :service="service"
            :showTitleRight="false"
        />
        <div
        class="service-item-wrap cursor-pointer"
        >
            <button v-if="showMoreButton" @click="loadMore" class="more-button">
                {{ $t('more') }}
            </button>
        </div>
    </div>
</template>

<script>
import StarXrServiceListItem from './StarXrServiceListItem.vue';

export default {
    name: 'StarXrServiceDetailRec',
    components: {
        StarXrServiceListItem
    },
    computed: {
        srvcSn() {
            return this.$route.params.idx;
        },
        visibleServices() {
            return this.services.slice(0, this.visibleCount);
        },
        showMoreButton() {
            return this.visibleCount < this.services.length;
        }
    },
    data() {
        return{
            services: [],
            visibleCount: 3
        }
    },
    methods: {
        /**
         * @method selectAdminServiceList
         * @note 서비스 목록 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        selectAdminServiceList() {
            this.$store.dispatch('selectServiceList')
            .then((res) => {
                this.services = res.servicesList.filter(service => service.srvcSn != this.srvcSn);
                this.visibleCount = 3;
            })
        },
        loadMore() {
            this.visibleCount += 3;
        }
    },
    watch: {
        srvcSn() {
            this.selectAdminServiceList();
        }
    },
    created() {
        this.selectAdminServiceList();
    }
}
</script>

<style scoped> 
.service-detail-rec-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

::v-deep .service-item {
    height: 160px;
}

.more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #707070;
}

.service-item-wrap {
    min-width: 295px;
    flex-basis: calc((100% - 90px) / 4);
}

</style>